import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export default function WeddingFirework(params) {
    return (
        <main className="main">
            <Description
                name={"Свадебный фейерверк"}
                text={
                    "Это комплекс пиротехнических эффектов, подобранных под индивидуальные и корпоративные запросы. Как правило, фейерверки подобного рода устраивают во время массовых церемоний и знаковых мероприятий."
                }
                gall={
                    <ul className="description__gallery">
                    <li className="description__gallery-item"><a className="description__gallery-link"
                            href="img/pages/wedding-firework/gallery/1.jpg" data-fslightbox="wedding-firework"><img
                                className="description__gallery-image" src="img/pages/wedding-firework/gallery/1.jpg"/></a>
                    </li>
                    <li className="description__gallery-item"><a className="description__gallery-link"
                            href="img/pages/wedding-firework/gallery/2.jpg" data-fslightbox="wedding-firework"><img
                                className="description__gallery-image" src="img/pages/wedding-firework/gallery/2.jpg"/></a>
                    </li>
                    <li className="description__gallery-item"><a className="description__gallery-link"
                            href="img/pages/wedding-firework/gallery/3.jpg" data-fslightbox="wedding-firework"><img
                                className="description__gallery-image" src="img/pages/wedding-firework/gallery/3.jpg"/></a>
                    </li>
                    <li className="description__gallery-item">
                        <a className="description__gallery-link"
                            href="img/pages/wedding-firework/gallery/4.jpg" data-fslightbox="wedding-firework"><img
                                className="description__gallery-image" src="img/pages/wedding-firework/gallery/4.jpg"/>
                                </a>
                             
                    </li>
                </ul>
                }
                fease={
                    <>
                    <video controls src="video/свадебный фейерверк.mp4" width="100%" height={'100%'}>
                            <source src="flower.mp4" type="video/свадебный фейерверк.mp4"/>
                            </video>
                    </>
                }
            />
             <Stages/>
             <ActionBlock/>
            <Subscribe/>
        </main>
    );
}
