
import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export function Gender_party_in_Ulyanovsk(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Купить или заказать Гендер Пати в Салют – Ульяновск"}
                    text={
                        "Гендер пати (гендерный салют) – невероятное зрелище на открытом пространстве, проводимое в честь праздника рождения ребенка. Купить Гендер Пати можно в нашем интернет – магазине по акционным скидкам. Торопитесь, пока это новинка!."
                    }
                    gall={
                        <ul className="description__gallery">
                        <li  itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                        <iframe  itemProp="contentUrl" src="https://vk.com/video_ext.php?oid=-42310319&id=456239583&hd=2&autoplay=0" width="100%" allow=" accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameborder="0" allowfullscreen></iframe>
    
                        </li>
                        <li  itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                        <iframe  itemProp="contentUrl" src="https://vk.com/video_ext.php?oid=-42310319&id=456239477&hd=2&autoplay=0"  width="100%"  allow=" accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameborder="0" allowfullscreen></iframe>
    
                        </li>
                        <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                        <video itemProp="contentUrl" controls src="video/гендер пати дым.MOV" width="100%" height={'100%'}>
                            <source src="flower.mp4" type="video/гендер пати дым.MOV"/>
                            </video>
    
                        </li>
                        
                        <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                            {/* <iframe width="100%" src="https://www.youtube.com/embed/WsD-0tMY2IY?si=EnifmZLeD8Y-Rt99"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe> */}
                                <video itemProp="contentUrl" controls src="video/дымовое гендер пати.mp4" width="100%" height={'100%'}>
                            <source src="flower.mp4" type="video/дымовое гендер пати.mp4"/>
                            </video>
    
                        </li>
                    </ul>
                    }
                    fease={
                        <>
                            <img src="img/pages/gender.jpg" alt="Профессиональные салюты" className="description__img"/>
                        </>
                    }
                />
               
                <Stages />
                <ActionBlock/>
                <Subscribe />
            </main>
        </>
    );
}
