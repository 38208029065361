import { useEffect } from "react"


export default function Meta({title, keywords, description, video}) {
    useEffect(() => {
      document.title = title;
      document.querySelector('meta[name="keywords"]').content = keywords;
      document.querySelector('meta[name="description"]').content= description;
      document.querySelector('meta[property="og:video"]').content= video;
      document.getElementById('titlevid').content = title;
      document.getElementById('opisvid').content = description;
    }, [title, keywords, description, video])
    return <></>;
}