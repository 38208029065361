import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
import "../css/salut-ulyanovsk.css";
import ModalMain from "../modul/modalMainPopup";

export default function SalutUlyanovsk(params) {
    return (
       <>
        <main className="main">
        <Description
                    name={"Салют Ульяновск"}
                    text={
                        "В жизни людей случаются яркие моменты, которые не хочется отпускать, прокручивая снова и снова. Мы хотим сделать любое знаковое для Вас событие запоминающимся, красочным и фееричным. Нам помогут необычные огненные эффекты, чьё разнообразие приятно Вас удивит."
                    }
                    gall={
                        <ul className="description__gallery">
                            <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                              <h3 style={{display:'none'}} itemProp="name">салют на день рождения</h3>
  <p  style={{display:'none'}}  itemProp="description">салют на день рождения</p>
                            <video itemProp="contentUrl" controls src="video/салют на день рождения.MP4" width="100%" height={'180px'}>
                            <source src="flower.mp4" type="video/салют на день рождения.MP4"/>
                            </video>
                            </li>
                            <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                              <h3 style={{display:'none'}} itemProp="name">салют на свадьбу</h3>
  <p  style={{display:'none'}}  itemProp="description">салют на свадьбу</p>
                            <video itemProp="contentUrl" controls src="video/салют на свадьбу.mp4" width="100%" height={'180px'}>
                            <source src="flower.mp4" type="video/салют на свадьбу.mp4"/>
                            </video>
                            </li>
                            <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                              <h3 style={{display:'none'}} itemProp="name">салют_Ульяновск</h3>
  <p  style={{display:'none'}}  itemProp="description">салют_Ульяновск</p>
                            <video itemProp="contentUrl" controls src="video/салют_Ульяновск.mp4" width="100%" height={'180px'}>
                            <source src="flower.mp4" type="video/салют_Ульяновск.mp4"/>
                            </video>
                                   
                            </li>
                            <li itemScope itemtype="http://schema.org/VideoObject" className="description__gallery-item">
                              <h3 style={{display:'none'}} itemProp="name">салют</h3>
  <p  style={{display:'none'}}  itemProp="description">салют</p>
                            <video itemProp="contentUrl" controls src="video/салют.MOV" width="100%" height={'180px'}>
                            <source src="flower.mp4" type="video/салют.MOV"/>
                            </video>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                        <source
                srcSet="img/pages/salut-ulyanovsk/bg.jpg"
                type="image/webp"
            />
            <img
                src="img/pages/salut-ulyanovsk/bg.jpg"
                alt="Салют Ульяновск"
                className="description__img"
            /></>
                    }
                />
            <Stages/>
            <ActionBlock/>
            <Subscribe/>
            

        </main>
        {/* <ModalMain/> */}
        
        </>
    );
}
