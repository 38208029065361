import {
    RouterProvider,
    createBrowserRouter,
    useLocation,
} from "react-router-dom";
import ScrollToTop from "./bloc/sctolToTop";
import KonstruktSite from "./bloc/Konstruct";
import Header from "./bloc/header";
import Main from "./pages/mein";
import SalutUlyanovsk from "./pages/salut-ulyanovsk";
import ProfessionalSalutes from "./pages/professional-salutes";
import PyrotechnicShow from "./pages/pyrotechnic-show";
import WeddingFirework from "./pages/wedding-firework";
import HeavySmoke from "./pages/heavy-smoke";
import Portfolio from "./pages/portfolio";
import Reviews from "./pages/reviews";
import Actions from "./pages/actions";
import Contacts from "./pages/contacts";
import { ColdFountains } from "./pages/cold-fountains";
import { FieryFigures } from "./pages/fiery-figures";
import { SparklesAndCrackers } from "./pages/sparkles-and-crackers";
import { ColoredSmoke } from "./pages/colored-smoke";
import { Gender_party_in_Ulyanovsk } from "./pages/Gender_party_in_Ulyanovsk";
import Articles from "./pages/articles";
import Footer from "./bloc/footer";
import PopupFos from "./modul/popubFos";
import Meta from "./modul/meta";

export function AppRouste(params) {
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Организация и продажа салютов и пиротехники - Салют Ульяновск"
                            }
                            description={
                                "Подготовка и сопровождение праздничных мероприятий зрелищным огненным шоу. Заказать салюты и фейерверки, горящие инсталляции и дымовые эффекты в Ульяновске и Ульяновской области, 73 регион."
                            }
                            keywords={
                                "Салют в Ульяновске, купить салют в Ульяновске, салют 73, Ульяновский фейерверк"
                            }
                            video={
                                 "video/салют_Ульяновск.mp4"
                            }

                        />
                        <Header />
                        <Main />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/salut-ulyanovsk",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Салют73 – Салюты и фейерверки в Ульяновске"
                            }
                            description={
                                "Подготовка и сопровождение праздничных мероприятий зрелищным огненным шоу. Заказать салюты и фейерверки, горящие инсталляции и дымовые эффекты в Ульяновске и Ульяновской области, 73 регион."
                            }
                            keywords={
                                "Салют в Ульяновске, купить салют в Ульяновске, салют 73, Ульяновский фейерверк"
                            }
                            video={"video/салют_Ульяновск.mp4"}
                        />
                        <Header />
                        <SalutUlyanovsk />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/professional-salutes",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать профессиональный салют в Ульяновске"
                            }
                            description={
                                "Не знаете, где заказать салют? Салют в Ульяновске от компании «Салют Ульяновск» - отличное дополнение к Вашему празднику, огненный красочный сюжет, построенный по Вашим запросам и пожеланиям."
                            }
                            keywords={
                                "профессиональный салют, салюты Ульяновска, заказать салют"
                            }
                            video={"video/пиротехническое шоу.mp4"}

                        />
                        <Header />
                        <ProfessionalSalutes />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/pyrotechnic-show",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать пиротехническое шоу в Ульяновске"
                            }
                            description={
                                "Заказать пиротехническое шоу в Ульяновске от компании «Салют Ульяновск» - отличное решение к Вашему празднику, включающее в себя комплекс различных огненных эффектов, представляющих целый сюжет, выстроенный по Вашим запросам и пожеланиям."
                            }
                            keywords={
                                "пиротехническое шоу в Ульяновске, с пиротехническим шоу, пиротехническое шоу заказать Ульяновск"
                            }
                            video={"video/пиротехническое шоу.mp4"}

                        />
                        <Header />
                        <PyrotechnicShow />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/wedding-firework",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать свадебный фейерверк в Ульяновске"
                            }
                            description={
                                "Свадебный фейерверк в Ульяновске от компании «Салют Ульяновск» - отличное дополнение к Вашему празднику, включающая в себя комплекс различных огненных эффектов, выполненных по Вашим запросам и пожеланиям."
                            }
                            keywords={
                                "свадебный фейерверк, заказать фейерверк, где купить фейерверки"
                            }
                            video={"video/пиротехническое шоу на свадьбы.mp4"}

                        />
                        <Header />
                        <WeddingFirework />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/heavy-smoke",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать тяжёлый дым в Ульяновске"
                            }
                            description={
                                "«Салют Ульяновск» поможет добавить Вашему празднику атмосферу сказочности, таинственности, использовав эффект «тяжёлого дыма», внешне напоминающего густой, ползущий по земле туман."
                            }
                            keywords={
                                "заказать тяжёлый дым, тяжёлый дым"
                            }
                            video={"video/тяжелый дым.mp4"}

                        />
                        <Header />
                        <HeavySmoke />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/portfolio",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={"Салют73 — Портфолио"}
                            description={
                                "Не портфолио, а наслаждение! А когда ваша свадьба? Мы знаем, как сделать её невероятно красивой!"
                            }
                            keywords={
                                "видео использование пиротехники, видео пиротехника, пиротехника салют видео, пиротехника фото, русская пиротехника видео, салют залп видео, салют фото, салют Фото 1картинки, свадебные салюты фото, свадебный салют видео, Фото 1с цветным дымом, цветной дым видео"
                            }
                            video={"video/салют_Ульяновск.mp4"}

                        />
                        <Header />
                        <Portfolio />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/reviews",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={"Салют73 — Отзывы"}
                            description={
                                "Читайте только честные отзывы о салютах и пиротехнике в Ульяновске."
                            }
                            keywords={
                                "читать отзывы на салюты, отзывы на салюты, смотреть отзывы салют ульяновск, честные отзывы на салюты"
                            }
                            video={"video/салют.MOV"}

                        />
                        <Header />
                        <Reviews />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/actions",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={"Акции Салют Ульяновск"}
                            description={
                                "Мы любим яркие эмоции и незабываемые впечатления! И хотим делиться с вами! А больше ярких эмоций только с нашими скидками и приятными бонусами!"
                            }
                            keywords={
                                "акции салют73, акции салют Ульяновск, смотреть акции салют73"
                            }
                        />
                        <Header />
                        <Actions />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/contacts",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={"Салют73 — Контакты"}
                            description={
                                "Основная информация о компании «Салют Ульяновск», предлагаемых товарах и услугах."
                            }
                            keywords={
                                "салют ульяновск, компания салютов и фейерверков, салюты региона 73"
                            }
                        />
                        <Header />
                        <Contacts />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/cold-fountains",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Купить холодные фонтаны в Ульяновске"
                            }
                            description={
                                "Взлетающие разноцветные искры, напоминающие извержение огненного вулкана в миниатюре – вот что такое огненный фонтан. Дорожка из огненных фонтанов – сказочное зрелище, делающее прогулку по ней незабываемой."
                            }
                            keywords={
                                "холодный фонтан, холодный фонтан фейерверк, фонтаны на свадьбу холодные, фонтаны холодные купить"
                            }

                        />
                        <Header />
                        <ColdFountains />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/fiery-figures",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать горящие фигуры и надписи в Ульяновске"
                            }
                            description={
                                "Проведение огненной инсталляции в Ульяновске компанией «Салют Ульяновск» - отличное дополнение к Вашему празднику, включающая в себя горящие буквы, символические изображения, выполненных по Вашим запросам и пожеланиям. Высокое качество выполнения при малых сроках поднотовки."
                            }
                            keywords={
                                "огненные фигуры, фигуры из огня, горящие сердца"
                            }
                        />
                        <Header />
                        <FieryFigures />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/sparkles-and-crackers",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Бенгальские огни и хлопушки в Ульяновске"
                            }
                            description={
                                "Компания «Салют Ульяновск» предлагает заказать большие бенгальские огни к мероприятию или событию. Они украсят любое празднование, разнообразив атмосферу."
                            }
                            keywords={
                                "Бенгальские огни в Ульяновске, большие бенгальские огни Ульяновска, купить бенгальские огни в Ульяновске, заказать бенгальские огни, купить хлопушки"
                            }
                            video={"video/пиротехнические вертушки.mp4"}

                        />
                        <Header />
                        <SparklesAndCrackers />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/colored-smoke",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Заказать цветной дым в Ульяновске"
                            }
                            description={
                                "«Салют Ульяновск» превратит Ваш праздник в сказку, добавив эффект «цветного дыма», придающего окружающему нереалистичность и таинственность. Необычные цвета, особенно при комбинации цветов и подсветок, подобранные нашими дизайнерами, усилят ощущения необычности, заставив каждого вернуться в детство."
                            }
                            keywords={
                                "цветной дым в Ульяновске, дымовые эффекты Ульяновска, эффекты цветным дымом от Салют Ульяновска, картины из дыма в Ульяновске"
                            }
                            video={"video/гендер пати дым.MOV"}

                        />
                        <Header />
                        <ColoredSmoke />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/Gender_party_in_Ulyanovsk",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Купить или заказать Гендер Пати в Салют – Ульяновск"
                            }
                            description={
                                "Ищете, где купить или заказать праздничный салют в честь Гендер Пати? Выбирайте у нас!"
                            }
                            keywords={
                                "гендер пати ,купить гендер пати ,салют гендер пати,гендерный салют"
                            }
                            video={"video/дымовое гендер пати.mp4"}

                        />
                        <Header />
                        <Gender_party_in_Ulyanovsk />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/articles",
            element: (
                <>
                    <ScrollToTop>
                        <Meta
                            title={
                                "Салют73 — Полезно знать"
                            }
                            description={
                                "Полезные и интересные статьи от нашей команды!"
                            }
                            keywords={
                                "полезные статьи о салютах, о салютах, статьи о салютах, статьи о пиротехнике"
                            }
                        />
                        <Articles />
                        <Footer />
                        <PopupFos />
                    </ScrollToTop>
                </>
            ),
        },
    ]);

    return <RouterProvider router={router} />;
}
